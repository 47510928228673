import styled from "styled-components";

export const Container = styled.div`
  width: 263px;
  border: 1px solid #ececec;
  text-decoration: none;

  img {
    height: 157px;
    object-fit: cover;
    width: 261px;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    display: block;
    padding: 15px 10px;
    color: black;
    text-decoration: none;
  }

  @media (max-width: 605px) {
    width: 100%;

    img {
      width: 100%;
      height: 170px;

      object-position: 0 0;
    }
  }
`;
