import Footer from "../Footer";
import Header from "../Header";

import styled from "styled-components";

const Layout: React.FC<any> = ({ children }) => {
  return (
    <>
      <Header />
      <LayoutContainer>{children}</LayoutContainer>
      <Footer />
    </>
  );
};

export default Layout;

export const LayoutContainer = styled.main`
  padding-top: 95px;
`;
