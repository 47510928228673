import { slideInLeft, fadeIn, slideInRight } from "react-animations";
import styled, { keyframes, css } from "styled-components";

const slideInLeftAnimation = keyframes`${slideInLeft}`;
const slideInRightAnimation = keyframes`${slideInRight}`;
const fadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled.section`
  width: 100%;
  max-width: 1920px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  margin-top: 250px;

  img {
    max-width: 1903px;
    object-fit: cover;
  }

  @media (max-width: 900px) {
    > img {
      height: 850px;
      max-width: 100%;
    }

    margin-top: 50px;

    p,
    span {
      font-size: 35px !important;
    }
  }

  @media (max-width: 499px) {
    > img {
      height: 950px;
    }
  }
`;

export const Wrapper = styled.div<{ visible?: boolean }>`
  width: 100%;
  max-width: 1160px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;

  height: 100%;

  @media (max-width: 1200px) {
    padding: 40px 20px 0;
  }

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

export const Left = styled.div<{ visible?: boolean }>`
  display: flex;
  position: absolute;
  align-self: flex-end;
  opacity: 0;

  ${({ visible }) =>
    visible &&
    css`
      animation: ${fadeInAnimation} 0.7s forwards,
        ${slideInLeftAnimation} 0.7s forwards;
    `}

  bottom: 0;

  @media (max-width: 1200px) {
    img {
      max-width: 450px;
    }
  }

  @media (max-width: 1050px) {
    img {
      max-width: 400px;
    }
  }

  @media (max-width: 900px) {
    position: static;
    align-self: center;
    margin-top: 10px;

    img {
      max-width: 350px;
    }
  }
`;

export const Right = styled.div<{ visible?: boolean }>`
  width: 100%;
  max-width: 564px;
  margin-left: auto;
  opacity: 0;

  ${({ visible }) =>
    visible &&
    css`
      animation: ${fadeInAnimation} 0.7s forwards,
        ${slideInRightAnimation} 0.7s forwards;
    `}

  a {
    color: #1ea418;
    font-size: 16px;
    background: white;
    width: 100%;
    border: 1px solid #1ea418;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 30px;

    padding: 10px;
  }

  @media (max-width: 1050px) {
    max-width: 450px;
  }

  @media (max-width: 900px) {
    margin-left: initial;
  }

  @media (max-width: 650px) {
    max-width: initial;
  }
`;
