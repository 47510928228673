import GlobalStyles from "./styles/GlobalStyles";
import Home from "./pages";

import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <Home />
      <ToastContainer />
    </>
  );
};

export default App;
