import { Container, Button } from "./styles";
import { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";

const HeaderMobile: React.FC = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.getElementsByTagName("body")[0].classList.add("modal-open");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("modal-open");
    }
  }, [open]);

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth > 965) {
        if (open) {
          setOpen(false);
        }
      }
    }
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [open]);

  return (
    <>
      <Button onClick={() => setOpen(!open)}>
        <GiHamburgerMenu size={20} />
      </Button>
      {open && (
        <Container>
          <nav>
            <a onClick={() => setOpen(false)} href="#como-funciona">
              Como funciona
            </a>
            <a onClick={() => setOpen(false)} href="#servicos">
              Serviços
            </a>
            <a onClick={() => setOpen(false)} href="#beneficios-e-vantagens">
              Benefícios e Vantagens
            </a>
            <a onClick={() => setOpen(false)} href="#especialista">
              Especialista
            </a>
            <a onClick={() => setOpen(false)} href="#contato">
              Contato
            </a>
            <a
              onClick={() => setOpen(false)}
              href="http://user-lubbri.omegacotech.com.br/login"
            >
              Login
            </a>
          </nav>
        </Container>
      )}
    </>
  );
};

export default HeaderMobile;
