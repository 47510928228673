import styled from "styled-components";

export const HeaderWrapper = styled.header`
  width: 100%;
  max-width: 100vw;
  height: 95px;
  padding: 20px;
  box-shadow: 0 3px 3px 0 egba(0, 0, 0, 0.16);

  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */

  z-index: 1000;
  background: white;

  position: fixed;

  border-bottom: 1px solid #ececec;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1160px;

  z-index: 1000;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Left = styled.div``;

export const Right = styled.nav`
  display: flex;
  align-items: center;
  gap: 30px;

  a {
    font-size: 16px;
    color: #707070;
    font-weight: 500;
    text-decoration: none;
  }

  a:last-child {
    color: white;
    background: #e64c15;
    font-weight: 400;
    width: 100px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }

  @media (max-width: 965px) {
    display: none;
  }
`;
