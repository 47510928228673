import { HeaderWrapper, HeaderContainer, Left, Right } from "./styles";
import Logo from "../../images/logo.png";
import HeaderMobile from "./HeaderMobile";

const Header: React.FC = () => {
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Left>
          <img src={Logo} alt="Logo da Lubbri" />
        </Left>
        <Right>
          <a href="#como-funciona">Como funciona</a>
          <a href="#servicos">Serviços</a>
          <a href="#beneficios-e-vantagens">Benefícios e Vantagens</a>
          <a href="#especialista">Especialista</a>
          <a href="#contato">Contato</a>
          <a
            href="http://user-lubbri.omegacotech.com.br/login"
            rel="noreferrer noopener"
            target="_blank"
          >
            Login
          </a>
        </Right>
      </HeaderContainer>
      <HeaderMobile />
    </HeaderWrapper>
  );
};

export default Header;
