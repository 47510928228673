// services/index.js

import axios from "axios";
import { v4 } from "uuid";

const instance = axios.create({
  baseURL: "https://lubbri.omegacotech.com.br/api",
  timeout: 30000,
});

export const handleError = (error: any) => {
  return Promise.reject(error);
};

instance.interceptors.request.use(
  async (request: any) => {
    const myUUID = v4();

    try {
      if (request.method === "get" && request.url?.includes("/seller/image")) {
      }
      if (
        (request.method === "delete" || request.method === "get") &&
        request.headers
      ) {
        request.headers["X-transaction-code"] = myUUID;
      }
      if (
        request.method === "post" ||
        request.method === "put" ||
        request.method === "patch"
      ) {
        request.data.transactionCode = myUUID;
      }
      if (
        !request.url?.endsWith("/user/create") &&
        !request.url?.endsWith("/authenticate")
      ) {
      }

      return request;
    } catch (error: any) {
      // exception to new token here

      return handleError(error);
    }
  },
  (error: any) => {
    return handleError(error);
  }
);

instance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (!error.response && error.code === "ECONNABORTED") {
      return handleError(error);
    }

    const { status } = error.response;

    if (status === 400 || status === 500) {
      return error.response;
    }

    return handleError(error);
  }
);

export default instance;
