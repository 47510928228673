import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px;
  border: 1px solid #ececec;
  border-radius: 3px;
  background: transparent;

  cursor: pointer;

  @media (min-width: 965px) {
    display: none;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 100vw;

  height: calc(100vh - 95px);

  padding: 20px;

  background: white;

  position: fixed;
  top: 95px;
  left: 0;

  z-index: 1000;

  @media (min-width: 965px) {
    display: none;
  }

  nav {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 30px;

    a {
      font-size: 20px;
      color: #707070;
      font-weight: 500;
      text-decoration: none;
    }

    a:last-child {
      color: white;
      background: #e64c15;
      font-weight: 400;
      width: 130px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
    }
  }
`;
