import { Container, Wrapper, Left, Right } from "./styles";
import SectionBG from "../../images/especialista-bg.png";
import SectionImage from "../../images/especialista.png";

import { useInView } from "react-intersection-observer";

const Especialista: React.FC = () => {
  const [ref, inView] = useInView({ threshold: 0, triggerOnce: true });

  return (
    <Container id="especialista" ref={ref}>
      <img src={SectionBG} alt="Imagem de fundo" />
      <Wrapper visible={inView}>
        <Left visible={inView}>
          <img src={SectionImage} alt="Imagem de especialista" />
        </Left>
        <Right visible={inView}>
          <p style={{ fontSize: "52px", fontWeight: "500" }}>E para você</p>
          <p
            style={{
              fontSize: 50,
              fontWeight: 700,
              color: "#e64c15",
              textAlign: "right",
            }}
          >
            especialista,
          </p>
          <p style={{ fontWeight: 500 }}>
            <span style={{ fontSize: 30 }}>não deixe de </span>{" "}
            <span
              style={{
                fontSize: 40,
                color: "#e64c15",
                position: "relative",
                bottom: "4px",
              }}
            >
              aumentar
            </span>
          </p>
          <p style={{ fontSize: 36, fontWeight: 500, textAlign: "right" }}>
            ainda mais
          </p>
          <p style={{ fontSize: "38px", fontWeight: "500" }}>seus lucros com</p>
          <p
            style={{
              fontSize: 45,
              fontWeight: 700,
              color: "#e64c15",
              textAlign: "right",
            }}
          >
            novos clientes!
          </p>
          <a
            href="http://seller-lubbri.omegacotech.com.br/cadastro"
            target="_blank"
            rel="noreferrer noopener"
            className="cadastro"
          >
            Cadastre agora mesmo sua loja
          </a>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Especialista;
