import ServicosSection from "../components/Servicos";
import ComoFunciona from "../components/ComoFunciona";
import TopBanner from "../components/TopBanner";
import { PageContainer, PageWrapper } from "../styles/GlobalStyles";
import Beneficios from "../components/Beneficios";
import Especialista from "../components/Especialista";
import Layout from "../components/Layout";

const Home: React.FC = () => {
  return (
    <Layout>
      <PageWrapper>
        <PageContainer>
          <TopBanner />
          <ComoFunciona />
          <ServicosSection />
          <Beneficios />
          <Especialista />
        </PageContainer>
      </PageWrapper>
    </Layout>
  );
};

export default Home;
