import { slideInDown, zoomIn, zoomOut } from "react-animations";
import styled, { keyframes, css } from "styled-components";

import { animations as globalAnimations } from "../../styles/GlobalStyles";

const slideInDownAnimation = keyframes`${slideInDown}`;
const zoomInAnimation = keyframes`${zoomIn}`;
const zoomOutAnimation = keyframes`${zoomOut}`;

export const animations = {
  zoomOut: css`
    animation: ${zoomOutAnimation} 0.7s forwards;
  `,
  zoomIn: css`
    animation: ${zoomInAnimation} 0.7s forwards;
  `,

  slideInDown: css`
    animation: ${slideInDownAnimation} 0.7s forwards;
  `,
};

export const Container = styled.div<{ visible?: boolean }>`
  width: 100%;
  max-width: 1160px;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 100px;
  ${({ visible }) => visible && globalAnimations.fadeIn}

  h2 {
    font-size: 50px;
    line-height: 54px;
    ${({ visible }) => visible && animations.slideInDown}
  }

  hr {
    border: none;
    margin-top: 20px;
    width: 150px;
    height: 6px;
    background: linear-gradient(
      to right,
      #2020e6 0%,
      #2020e6 20%,
      #1ea418 20%,
      #1ea418 100%
    );
  }

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 750px) {
    margin-top: 50px;

    align-items: flex-start;
  }
`;

export const Cards = styled.div<{ visible?: boolean }>`
  width: 100%;
  display: flex;
  gap: 20px;

  margin-top: 50px;
  justify-content: center;

  div.cadastro {
    color: white;
    background: #e64c15;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 263px;
    text-decoration: none;
  }

  > div {
    opacity: 0;

    &:nth-child(1) {
      ${({ visible }) => visible && globalAnimations.fadeIn}
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      ${({ visible }) => visible && globalAnimations.fadeIn}
      animation-delay: 0.8s;
    }
    &:nth-child(3) {
      ${({ visible }) => visible && globalAnimations.fadeIn}
      animation-delay: 1.4s;
    }
    &:nth-child(4) {
      ${({ visible }) => visible && globalAnimations.fadeIn}
      animation-delay: 2s;
    }
  }

  flex-wrap: wrap;

  @media (max-width: 1200px) {
    max-width: 800px;
  }

  @media (max-width: 605px) {
    max-width: 550px;

    div.cadastro {
      height: 200px;
      width: 100%;
    }
  }
`;
