import styled, { css, keyframes } from "styled-components";
import { slideInLeft, fadeIn, slideInRight } from "react-animations";


const slideInLeftAnimation = keyframes`${slideInLeft}`;
const slideInRightAnimation = keyframes`${slideInRight}`;
const fadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled.section`
  width: 100%;
  max-width: 1919px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  margin-top: 240px;

  img {
    object-fit: cover;
    max-width: 1903px;
  }

  @media (max-width: 900px) {
    > img {
      height: 900px;
      max-width: 100%;
    }

    margin-top: 50px;
  }

  @media (max-width: 750px) {
    > img {
      height: 1000px;
    }
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  height: 100%;

  width: 100%;
  max-width: 1160px;

  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    padding: 40px 20px 0;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Left = styled.div<{ visible?: boolean }>`
  width: 100%;
  max-width: 565px;
  opacity: 0;

  h2 {
    font-size: 50px;
    line-height: 54px;
    color: white;
  }

  ${({ visible }) =>
    visible &&
    css`
      animation: ${fadeInAnimation} 0.7s forwards,
        ${slideInLeftAnimation} 0.7s forwards;
    `}

  hr {
    border: none;
    margin-top: 20px;
    width: 150px;
    height: 6px;
    background: linear-gradient(
      to right,
      #2020e6 0%,
      #2020e6 20%,
      #1ea418 20%,
      #1ea418 100%
    );
  }

  a {
    color: white;
    background: #e64c15;
    padding: 15px 30px;
    margin-top: 35px;

    display: flex;
    width: fit-content;
    border-radius: 5px;
  }

  ul {
    margin-top: 20px;
  }

  li {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    color: white;
    margin-left: 1rem;
  }
`;

export const Right = styled.div<{ visible?: boolean }>`
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;

  display: flex;

  ${({ visible }) =>
    visible &&
    css`
      animation: ${fadeInAnimation} 0.7s forwards,
        ${slideInRightAnimation} 0.7s forwards;
    `}

  @media (max-width: 1070px) {
    img {
      max-width: 500px;
    }
  }

  @media (max-width: 950px) {
    img {
      max-width: 450px;
    }
  }

  @media (max-width: 650px) {
    img {
      max-width: 350px;
    }
  }

  @media (max-width: 900px) {
    position: static;
  }
`;
