import {
  FooterWrapper,
  FooterContainer,
  Left,
  SocialMedia,
  Center,
  Right,
  Top,
  Bottom,
  ModalContainer,
} from "./styles";
import Logo from "../../images/logo-branca.png";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaTelegram,
  FaTiktok,
  FaInstagramSquare,
} from "react-icons/fa";

import { useCallback, useEffect, useState } from "react";
import api from "../../services/api";
import { handleToast } from "../../utils/handleToast";
import Modal from "@mui/material/Modal";

const Footer: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    termsOfUse: { html: "" },
    privacyPolicy: { html: "" },
  });

  const [termsOfUse, setTermsOfUse] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [socialMedia, setSocialMedia] = useState<any>([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await api.get(`/system`);
      const socialMediaResponse = await api.get(`/social-media-list`);

      setSocialMedia(socialMediaResponse?.data?.data);

      setData(response?.data?.data?.items[0]);
    };

    fetch();
  }, []);

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      setLoading(true);

      try {
        const res = await api.post("/contact", {
          data: {
            email,
            message,
          },
        });

        if (res?.data?.error) {
          handleToast(true, res?.data?.message);
        } else {
          handleToast(false, "Mensagem enviada");
        }
      } catch (e: any) {
        handleToast(true, e?.response?.data?.message);
      } finally {
        setLoading(false);
        setEmail("");
        setMessage("");
      }
    },
    [message, email]
  );

  return (
    <FooterWrapper>
      <FooterContainer>
        <Top>
          <Left>
            <img src={Logo} alt="Logo" />
            <p>
              Proin eu posuere quam, suscipit molestie nisl. Nullam tincidunt
              pulvinar metus, at vestibulum ex sagittis ut. Vivamus sit amet ex
              vitae nisl imperdiet convallis ac
            </p>
            <SocialMedia>
              {socialMedia.map((item: any) => {
                let socialMedia = null;

                if (item.socialName === "FACEBOOK")
                  socialMedia = <FaFacebookSquare />;
                if (item.socialName === "INSTAGRAM")
                  socialMedia = <FaInstagramSquare />;
                if (item.socialName === "TIKTOK") socialMedia = <FaTiktok />;
                if (item.socialName === "TWITTER")
                  socialMedia = <FaTwitterSquare />;
                if (item.socialName === "LINKEDIN")
                  socialMedia = <FaLinkedin />;
                if (item.socialName === "TELEGRAM")
                  socialMedia = <FaTelegram />;

                if (socialMedia) {
                  return (
                    <a
                      href={
                        item.socialLink.includes("http://") ||
                        item.socialLink.includes("https://")
                          ? item.socialLink
                          : "https://" + item.socialLink
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ color: "white" }}
                    >
                      {socialMedia}
                    </a>
                  );
                }

                return null;
              })}
            </SocialMedia>
          </Left>
          <Center>
            <h3>Navegação</h3>
            <ul>
              <li>
                <a href="#como-funciona">Como funciona</a>
              </li>
              <li>
                <a href="#servicos">Serviços</a>
              </li>
              <li>
                <a href="#beneficios-e-vantagens">Benefícios e vantagens</a>
              </li>
              <li>
                <a href="#especialista">Especialistas</a>
              </li>
            </ul>
          </Center>
          <Right id="contato">
            <h3>Fale conosco</h3>
            <form onSubmit={onSubmit}>
              <label>E-mail:</label>
              <input
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>Mensagem:</label>
              <textarea
                placeholder="Mensagem"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button disabled={loading}>Enviar</button>
            </form>
          </Right>
        </Top>
        <Bottom>
          <button onClick={() => setTermsOfUse(true)}>Termos de uso</button>
          <hr />
          <button onClick={() => setPrivacyPolicy(true)}>
            Políticas de privacidade
          </button>
          <hr /> <span>Anúncios Baseados em Interesses</span> <hr />
          <span>
            {" "}
            &copy;
            {new Date().getFullYear()} Lubbri Car Service
          </span>
        </Bottom>
      </FooterContainer>
      <Modal open={termsOfUse} onClose={() => setTermsOfUse(false)}>
        <ModalContainer
          dangerouslySetInnerHTML={{ __html: data?.termsOfUse?.html }}
        />
      </Modal>
      <Modal open={privacyPolicy} onClose={() => setPrivacyPolicy(false)}>
        <ModalContainer
          dangerouslySetInnerHTML={{ __html: data?.privacyPolicy?.html }}
        />
      </Modal>
    </FooterWrapper>
  );
};

export default Footer;
