import { slideInLeft, fadeIn, slideInRight } from "react-animations";
import styled, { keyframes, css } from "styled-components";

const slideInLeftAnimation = keyframes`${slideInLeft}`;
const slideInRightAnimation = keyframes`${slideInRight}`;
const fadeInAnimation = keyframes`${fadeIn}`;

const animations = {
  fadeIn: css`
    animation: ${fadeInAnimation} 0.7s forwards;
  `,

  slideInLeft: css`
    animation: ${slideInLeftAnimation} 0.7s forwards;
  `,

  slideInRight: css`
    animation: ${slideInRightAnimation} 0.7s forwards;
  `,
};

export const Container = styled.section<{ visible?: boolean }>`
  width: 100%;
  max-width: 1160px;
  display: flex;

  margin-top: 80px;
  gap: 30px;

  opacity: 0;

  ${({ visible }) => (visible ? animations.fadeIn : "")}
  h2 {
    ${({ visible }) => (visible ? animations.slideInLeft : "")}
  }

  > img {
    max-width: 450px;
    object-fit: cover;
    align-self: center;

    ${({ visible }) => (visible ? animations.slideInRight : "")}
  }

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 475px) {
    > img {
      max-width: 300px;
    }
  }
`;

export const Left = styled.div<{ visible?: boolean }>`
  width: 100%;
  max-width: 565px;

  h2 {
    font-size: 50px;
    line-height: 54px;

    ${({ visible }) => visible && animations.slideInLeft}
  }

  hr {
    border: none;
    margin-top: 20px;
    width: 150px;
    height: 6px;
    background: linear-gradient(
      to right,
      #2020e6 0%,
      #2020e6 20%,
      #1ea418 20%,
      #1ea418 100%
    );

    ${({ visible }) => visible && animations.slideInLeft}
  }

  > p {
    margin-top: 20px;
    font-size: 20px;
    color: #5e5e5e;

    ${({ visible }) => visible && animations.slideInLeft}
  }

  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  justify-content: center;
  row-gap: 15px;

  margin-top: 50px;
`;

export const Card = styled.div<{ visible?: boolean }>`
  background: white;
  border-radius: 5px;
  width: 168px;
  border: 1px solid #ececec;

  opacity: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  padding: 20px 10px;

  .icon {
    width: 53px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1ea418;
    border-radius: 50%;
  }

  h4 {
    font-size: 11px;
    color: #e64c15;
  }

  p {
    font-size: 13px;
    color: #5e5e5e;
    text-align: center;
  }

  &:nth-child(1) {
    ${({ visible }) => visible && animations.fadeIn}
    animation-delay: 0.3s;
  }
  &:nth-child(2) {
    ${({ visible }) => visible && animations.fadeIn}
    animation-delay: 0.6s;
  }
  &:nth-child(3) {
    ${({ visible }) => visible && animations.fadeIn}
    animation-delay: 0.9s;
  }
  &:nth-child(4) {
    ${({ visible }) => visible && animations.fadeIn}
    animation-delay: 1.2s;
  }
  &:nth-child(5) {
    ${({ visible }) => visible && animations.fadeIn}
    animation-delay: 1.5s;
  }
`;
