import { Container, Left, Right, Wrapper } from "./styles";
import SectionBG from "../../images/servicos-bg.png";
import SectionImage from "../../images/servicos.png";

import { useInView } from "react-intersection-observer";

const ServicosSection: React.FC = () => {
  const [ref, inView] = useInView({ threshold: 0, triggerOnce: true });

  return (
    <Container id="servicos" ref={ref}>
      <img src={SectionBG} alt="Imagem de fundo" />
      <Wrapper>
        <Left visible={inView}>
          <h2>Nossos serviços</h2>
          <hr />
          <ul>
            <li>Troca de óleo</li>
            <li>Filtro de motor</li>
            <li>Troca de Filtro de Gasolina</li>
            <li>Troca de filtro de cabine (ar-condicionado e/ou ventilação)</li>
            <li>Troca de filtro de ar</li>
          </ul>
          <a
            href="http://user-lubbri.omegacotech.com.br/cadastro-usuario"
            target="_blank"
            rel="noreferrer noopener"
            className="cadastro"
          >
            Cadastre-se já
          </a>
        </Left>
        <Right visible={inView}>
          <img src={SectionImage} alt="Imagem do banner" />
        </Right>
      </Wrapper>
    </Container>
  );
};

export default ServicosSection;
