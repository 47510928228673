import { Container } from "./styles";

interface IProps {
  imgAddress: string;
  text: string;
  link: string;
}

const Card: React.FC<IProps> = ({ imgAddress, text, link }) => {
  return (
    <Container>
      <img src={imgAddress} height={157} alt="Imagem do card" />
      <p>{text}</p>
    </Container>
  );
};

export default Card;
