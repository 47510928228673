import { Container, Left, Cards, Card } from "./styles";
import SectionImage from "../../images/como-funciona.png";
import Passo1 from "../../images/passo1.png";
import Passo2 from "../../images/passo2.png";
import Passo3 from "../../images/passo3.png";
import Passo4 from "../../images/passo4.png";
import Passo5 from "../../images/passo5.png";

import { useInView } from "react-intersection-observer";

const ComoFunciona: React.FC = () => {
  const [ref, inView] = useInView({ threshold: 0, triggerOnce: true });

  return (
    <Container id="como-funciona" ref={ref} visible={inView}>
      <Left visible={inView}>
        <h2>Como a Lubbri funciona</h2>
        <hr />
        <p>
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from 45 BC, making
          it over 2000
        </p>
        <Cards>
          <Card visible={inView}>
            <div className="icon">
              <img src={Passo1} alt="ícone" />
            </div>
            <h4>Passo 1</h4>
            <p>Cadastre seu carro em nossa plataforma.</p>
          </Card>
          <Card visible={inView}>
            <div className="icon">
              <img src={Passo2} alt="ícone" />
            </div>
            <h4>Passo 2</h4>
            <p>Escolha um de nossos serviços.</p>
          </Card>
          <Card visible={inView}>
            <div className="icon">
              <img src={Passo3} alt="ícone" />
            </div>
            <h4>Passo 3</h4>
            <p>Agende a data e horário para realizar o serviço.</p>
          </Card>
          <Card visible={inView}>
            <div className="icon">
              <img src={Passo4} alt="ícone" />
            </div>
            <h4>Passo 4</h4>
            <p>Realize o pagamento pela plataforma.</p>
          </Card>
          <Card visible={inView}>
            <div className="icon">
              <img src={Passo5} alt="ícone" />
            </div>
            <h4>Passo 5</h4>
            <p>Leve seu carro ao local do serviço agendado.</p>
          </Card>
        </Cards>
      </Left>
      <img src={SectionImage} alt="Imagem da secao" />
    </Container>
  );
};

export default ComoFunciona;
