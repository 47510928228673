import { Container, Cards } from "./styles";
import BeneficiosImg3 from "../../images/beneficios-3.png";
import BeneficiosImg2 from "../../images/beneficios-2.png";
import BeneficiosImg1 from "../../images/beneficios-1.png";
import Card from "./Card";

import { useInView } from "react-intersection-observer";

const Beneficios: React.FC = () => {
  const [ref, inView] = useInView({ threshold: 0, triggerOnce: true });

  return (
    <Container id="beneficios-e-vantagens" visible={inView} ref={ref}>
      <h2>Benefícios e Vantagens</h2>
      <hr />
      <Cards visible={inView}>
        <Card
          imgAddress={BeneficiosImg1}
          link=""
          text="Encontre melhores especialistas de troca de óleo da sua região
          "
        />
        <div className="cadastro">Cadastre-se já</div>

        <Card
          imgAddress={BeneficiosImg2}
          link=""
          text="Nunca mais vai precisar esperar sua vez para trocar o óleo do seu carro
          "
        />
        <Card
          imgAddress={BeneficiosImg3}
          link=""
          text="Saiba qual especialista tem o lubrificante certo para seu carro de acordo com o fabricante"
        />
      </Cards>
    </Container>
  );
};

export default Beneficios;
