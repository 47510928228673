import styled from "styled-components";

export const FooterWrapper = styled.footer`
  width: 100%;
  max-width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #1ea418;
  padding: 20px;
`;

export const FooterContainer = styled.div`
  width: 100%;
  max-width: 1160px;

  padding: 20px 0 0;
`;

export const Left = styled.div`
  p {
    margin-top: 40px;
    font-size: 14px;
    color: white;
    max-width: 466px;
    line-height: 18px;
    font-weight: 400;
  }
`;

export const Top = styled.div`
  display: flex;
  column-gap: 80px;
  row-gap: 50px;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    justify-content: left;
    max-width: 1000px;
  }

  @media (max-width: 765px) {
    flex-direction: column;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    color: white;
  }
`;

export const Center = styled.div`
  h3 {
    font-size: 20px;
    font-weight: 500;
    color: white;
  }

  ul {
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  li {
    margin-left: 1rem;

    &,
    a {
      color: white;
      font-size: 14px;
      text-decoration: none;
    }
  }
`;

export const Right = styled.div`
  h3 {
    font-size: 20px;
    font-weight: 500;
    color: white;
  }

  form {
    margin-top: 10px;

    label {
      color: white;
      font-size: 14px;
      text-decoration: none;
      margin-top: 15px;
      display: block;
    }

    input,
    textarea {
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ececec;
      background: white;
      outline: none;
      margin-top: 5px;
      width: 250px;

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #bababa;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #bababa;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #bababa;
      }
    }

    textarea {
      height: 100px;
      resize: none;
    }

    button {
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1ea418;
      border: 1px solid #1ea418;
      margin-top: 10px;
      background: white;
      border-radius: 5px;

      transition: 0.15s linear;

      cursor: pointer;

      :disabled {
        opacity: 0.7;
      }
    }
  }

  @media (max-width: 765px) {
    form {
      input,
      textarea {
        width: 100%;
        display: block;
      }
    }
  }
`;

export const Bottom = styled.div`
  width: 100%;
  padding: 20px 0 0;
  border-top: 1px solid white;
  flex-wrap: wrap;

  margin-top: 20px;

  display: flex;
  justify-content: center;
  gap: 10px;

  button,
  span {
    color: white;
    background: none;
    outline: none;
    border: none;
    font-size: 14px;
  }

  button {
    cursor: pointer;
  }

  hr {
    background: white;
    width: 1px;
    border: none;
  }
`;

export const ModalContainer = styled.div`
  background: white;
  border-radius: 3px;
  padding: 20px;
  min-width: 50vw;
  margin: 0 20px;
  border: none;
  outline: none;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;
