import { Container, Wrapper } from "./styles";
import TopBannerImage from "../../images/top-banner.png";
import TopBannerPerson from "../../images/top-banner-person.png";

const TopBanner: React.FC = () => {
  return (
    <Container>
      <img src={TopBannerImage} alt="Imagem do banner" width={1920} />
      <Wrapper>
        <div>
          <h1>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h1>
          <p>
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s,
          </p>
          <a
            href="http://user-lubbri.omegacotech.com.br"
            target="_blank"
            rel="noreferrer noopener"
            className="cadastro"
          >
            Cadastre-se já
          </a>
        </div>
        <img src={TopBannerPerson} alt="Imagem do banner" />
      </Wrapper>
      <hr />
    </Container>
  );
};

export default TopBanner;
