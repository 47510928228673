import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  max-width: 1920px;
  overflow: hidden;

  position: relative;

  img {
    object-fit: cover;
  }

  hr {
    margin-top: -4px;
    width: 100%;
    height: 10px;
    background: #1ea418;
    border: none;
    outline: none;
    display: block;
  }

  @media (max-width: 1200px) {
    > img {
      object-position: -400px;
    }
  }

  @media (max-width: 760px) {
    > img {
      object-position: -200px;
    }
  }

  @media (max-width: 600px) {
    > img {
      object-position: -400px;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1160px;

  margin: 0 auto;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;

  img {
    align-self: flex-end;
    margin-bottom: -26px;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    img {
      max-width: 220px;
      margin-bottom: -10px;
    }
  }

  @media (max-width: 600px) {
  }

  h1 {
    font-size: 35px;
    color: white;
    font-weight: 500;
    max-width: 664px;
    line-height: 52px;
  }

  p {
    color: white;
    font-size: 24px;
    line-height: 35px;
    max-width: 664px;
    margin-top: 10px;
  }

  a {
    color: white;
    background: #e64c15;
    padding: 15px 30px;
    margin-top: 35px;

    display: flex;
    width: fit-content;
    border-radius: 5px;
  }

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;
