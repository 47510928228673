import { slideInLeft, fadeIn, slideInRight, fadeOut } from "react-animations";
import styled, { keyframes, css, createGlobalStyle } from "styled-components";

const slideInLeftAnimation = keyframes`${slideInLeft}`;
const slideInRightAnimation = keyframes`${slideInRight}`;
const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;

export const animations = {
  fadeIn: css`
    animation: ${fadeInAnimation} 0.7s forwards;
  `,
  fadeOut: css`
    animation: ${fadeOutAnimation} 0.7s forwards;
  `,

  slideInLeft: css`
    animation: ${slideInLeftAnimation} 0.7s forwards;
  `,

  slideInRight: css`
    animation: ${slideInRightAnimation} 0.7s forwards;
  `,
};

const GlobalStyles = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto';
  }

  .modal-open{
    overflow:hidden;
  }

  a{
    text-decoration:none;
  }

  *{
    scroll-margin-top: 110px;
    scroll-behavior: smooth;
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  max-width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageContainer = styled.div`
  width: 100%;
  max-width: 1920px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default GlobalStyles;
